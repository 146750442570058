import { Controller } from "@hotwired/stimulus"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export default class extends Controller {
  static targets = ["sticky", "svg"]

  connect () {
    this.createAnimation()
  }

  createAnimation () {
    gsap.to(this.svgTarget, {
      rotation: 360,
      duration: 3000,
      ease: "none",
      scrollTrigger: {
        trigger: this.stickyTarget,
        start: "top center",
        end: "bottom center",
        scrub: true
      }
    })
  }
}
